import $ from "jquery";
// import Swiper, { Pagination, Navigation } from 'swiper';
import AOS from 'aos';

// import 'normalize.css';
// import 'swiper/swiper-bundle.css';
import 'aos/dist/aos.css';
import '../scss/global.scss';
import '../scss/works.scss';

// import '../scss/index.scss';

// Swiper.use([Navigation, Pagination]);

$(document).ready(function () {
    AOS.init();

    //   var swiper = new Swiper('.swiper-container', {
    //     slidesPerView: 1,
    //     spaceBetween: 30,
    //     centeredSlides: true,
    //     roundLengths: true,
    //     loop: true,
    //     pagination: {
    //       el: '.swiper-pagination',
    //       clickable: true,
    //     },
    //     breakpoints: {
    //       640: {
    //         slidesPerView: 2,
    //         spaceBetween: 20,
    //       },
    //       1024: {
    //         slidesPerView: 3,
    //         spaceBetween: 50,
    //       },
    //     }
    //   });

    //   $('.nav__btn').on('click', function() {
    //     $('.slip__navbar').toggleClass('collapsed');
    //   });
});



const header = document.querySelector('header');

let topOfNav = header.offsetTop;


function fixNav() {
    if (window.scrollY >= topOfNav) {
        document.body.classList.add('header--fixed');
    } else {
        document.body.classList.remove('header--fixed');
    }
}

window.addEventListener('scroll', fixNav);


document.addEventListener("DOMContentLoaded", function () {
    // alert('test');
});
